import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs';
// Plugins
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import * as Sentry from '@sentry/capacitor';
import { Purchases } from '@revenuecat/purchases-capacitor';
import OneSignal from 'onesignal-cordova-plugin';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  /**
   * This Service logs events in Firebase and Sentry
   */

  constructor(
    private platform: Platform,
    private router: Router) { }

  /**
   * Enable Firebase Logging
   */
  async enableFirebase() {
    try {
      if (this.platform.is('capacitor')) {
        const enabled = environment.production; // Enable only on production
        await FirebaseAnalytics.setEnabled({
          enabled,
        });
        // Send firebase instance id to revenue cat
        const { appInstanceId } = await FirebaseAnalytics.getAppInstanceId();
        if (typeof appInstanceId === 'string') {
          await Purchases.setFirebaseAppInstanceID(
            {
              firebaseAppInstanceID: appInstanceId
            }
          );
        }
        if (enabled) {
          console.log('Firebase is enabled');
        }
      }
    } catch (error) {
      console.log('Error in LoggerService.enableFirebase()', error);
    }
  }

  /**
   * Listen for route changes and report them to Firebase.
   * Sentry does this automatically.
   */
  async enableRouteLogging() {
    try {
      // Track every route change
      this.router.events.pipe(
        filter((e: RouterEvent) => e instanceof NavigationEnd),
      ).subscribe(async (e: RouterEvent) => {

        // Track screen view
        setTimeout(() => {
          if (document?.title && this.platform.is('capacitor')) {
            FirebaseAnalytics.setCurrentScreen({
              screenName: String(document.title),
              screenClassOverride: String(document.title),
            });
          }
          console.log('Log Screen View:', document.title);
        });

      });

    } catch (error) {
      console.log('Error in LoggerService.sentryMessage()', error);
    }
  }

  /**
   * Log Events in Firebase
   */
  async logEvent(name: string, params?: any) {
    try {
      if (this.platform.is('capacitor')) {
        // // Log on Firebase
        await FirebaseAnalytics.logEvent({
          name,
          params,
        });
      }
      console.log('Firebase - Log Event:', name, params);
    } catch (error) {
      console.log('Firebase - Error', error);
    }
  }

  /**
   * Measure screenviews
   *
   * @see https://firebase.google.com/docs/analytics/screenviews
   * @see https://support.google.com/analytics/answer/9234069
   */
  logScreenViewEvent(screenName: string, screenClassOverride?: string, params?: Record<string, string>) {
    if (!params) {
      params = {};
    }
    params.firebase_screen = screenName;
    params.firebase_screen_class = screenClassOverride || screenName;
    return this.logEvent('screen_view', params);
  }

  logButtonPressEvent(params: Record<string, string>) {
    return this.logEvent('button_press', params);
  }

  logShareEvent(params: FirebaseShareParam) {
    return this.logEvent('share', params);
  }

  logLoginEvent(params: FirebaseLoginParam) {
    return this.logEvent('login', params);
  }

  logSignUpEvent(params: FirebaseSignUpParam) {
    return this.logEvent('sign_up', params);
  }

  logViewItemEvent(params: FirebaseECommerceParam) {
    return this.logEvent('view_item', params);
  }

  /**
   * Track add_to_cart event
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=de&client_type=gtag#add_to_cart
   */
  logAddToCartEvent(params: FirebaseECommerceParam) {
    return this.logEvent('add_to_cart', params);
  }

  logBeginCheckoutEvent(params: FirebaseECommerceParam) {
    return this.logEvent('begin_checkout', params);
  }

  logAddPaymentInfo(params: FirebaseAddPaymentParam) {
    return this.logEvent('add_payment_info', params);
  }

  logRemoveFromCartEvent(params: Record<string, string>) {
    return this.logEvent('remove_from_cart', params);
  }

  logPurchaseEvent(params: FirebasePurchaseParam) {
    return this.logEvent('purchase', params);
  }

  logVideoPlayEvent(params: Record<string, string>) {
    return this.logEvent('video_play', params);
  }

  logExternalLinkClickEvent(params: Record<string, string>) {
    return this.logEvent('external_link_click', params);
  }

  logAffiliateLinkClickEvent(params: Record<string, string>) {
    return this.logEvent('affiliate_link_click', params);
  }

  /**
   * Set an ID for the current User
   *
   * @param userId Email or Database ID
   */
  async setUser(user: Sentry.User) {
    try {
      if (this.platform.is('capacitor')) {
        // Set User in Sentry
        if (user) {
          Sentry.setUser(user);
        }
        // Set User in Firebase
        if (typeof user?.id === 'string') {
          try {
            OneSignal.User.addAlias('unidy_id', user.id);
          } catch (error) {
            console.log('OneSignal Error:', error);
          }

          try {
            await FirebaseAnalytics.setUserId({
              userId: user.id,
            });
          } catch (error) {
            console.log('Firebase Error:', error);
          }

        }
        if (typeof user?.email === 'string') {
          try {
            OneSignal.login(user.email);
            OneSignal.User.addEmail(user.email);
          } catch (error) {
            console.log('OneSignal Error:', error);
          }

          try {
            await FirebaseAnalytics.setUserProperty({
              key: 'email',
              value: user.email
            });
          } catch (error) {
            console.log('OneSignal Error:', error);
          }
        }
        console.log('User Data sent to OneSignal & Firebase');
      }
    } catch (error) {
      console.log('Error in LoggerService.setUserId()', error);
    }
  }

  removeUserData() {
    try {
      OneSignal.logout();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Use this function to send technical logs to Sentry
   */
  sentryMessage(message: string, contexts?: any) {
    try {
      if (this.platform.is('capacitor')) {
        return Sentry.captureMessage(message, {
          contexts
        });
      }
    } catch (error) {
      console.log('Error in LoggerService.sentryMessage()', error);
    }
  }

  async sentryEvent(event: Sentry.Event) {
    if (this.platform.is('capacitor')) {
      return Sentry.captureEvent(event);
    }
  }

  async sentryException(event: Sentry.Exception) {
    if (this.platform.is('capacitor')) {
      return Sentry.captureException(event);
    }
  }

  /**
   * Check by path if it's a post
   */
  private isPost(path: string) {

    if (typeof path !== 'string') {
      return false;
    }

    const supportedPaths = ['news', 'schlagzeilen', 'newsticker', 'event'];
    const basePath = path.split('/').filter(p => p).shift();
    return supportedPaths.includes(basePath);
  }

}

export interface FirebaseShareParam {
  method: string; // Twitter, Facebook
  content_type?: 'post' | 'video' | 'short_video' | 'podcast';
  item_id?: string;
}

export interface FirebaseSignUpParam {
  method?: 'Apple' | 'Google' | 'Email';
}

export interface FirebaseLoginParam {
  method?: 'Apple' | 'Google' | 'Email' | 'Unidy';
}

export interface FirebaseAddPaymentParam extends FirebaseECommerceParam {
  payment_type?: PaymentMethod;
}

export interface FirebasePurchaseParam extends FirebaseECommerceParam {
  transaction_id: string;
}

export interface FirebaseECommerceParam {
  currency: 'EUR';
  value: number;
  coupon?: string;
  items: FirebaseItemParam[];
}

export interface FirebaseItemParam {
  item_id?: string;
  item_name: string;
  coupon?: string;
  item_category?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  price?: number;
  quantity?: number;
}

export enum PaymentMethod {
  ApplePay = 'Apple Pay',
  GooglePay = 'Google Pay',
  CreditCard = 'Kreditkarte',
  PayPal = 'PayPal',
}
