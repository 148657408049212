/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Capacitor } from '@capacitor/core';

/**
 * --- IMPORTANT ---
 * Routes should match with the website of BTC-ECHO.
 * THis is important für Google / Firebase analytics.
 */

const routesDesktop: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/desktop/user-area/user-area.module').then( m => m.UserAreaPageModule)
  },
  {
    title: 'Login',
    path: 'login',
    loadChildren: () => import('./pages/user/login/login.module').then( m => m.LoginPageModule)
  },
  {
    title: 'OAuthCallbackPage',
    path: 'oauth/callback',
    loadChildren: () => import('./pages/oauth-callback/oauth-callback.module').then( m => m.OauthCallbackPageModule)
  },
  {
    title: 'ErrorPage',
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    title: 'NotFoundPage',
    path: '**',
    redirectTo: 'error'
  }
];

const routesMobile: Routes = [
  {
    title: 'NewsFeedTabsPageRedirect',
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    title: 'SearchPage',
    path: 'search',
    loadChildren: () => import('./pages/news/news-search/news-search.module').then( m => m.CoinSearchPageModule)
  },
  {
    title: 'NewsDetailsPage',
    path: 'news/:slug',
    loadChildren: () => import('./pages/news/news-details/news-details.module').then( m => m.NewsDetailsPageModule)
  },
  {
    title: 'NewsDetailsPage',
    path: 'newsticker/:slug',
    loadChildren: () => import('./pages/news/news-details/news-details.module').then( m => m.NewsDetailsPageModule)
  },
  {
    title: 'NewsDetailsPage',
    path: 'schlagzeilen/:slug',
    loadChildren: () => import('./pages/news/news-details/news-details.module').then( m => m.NewsDetailsPageModule)
  },
  {
    title: 'NewsDetailsPage',
    path: 'videos/:slug',
    loadChildren: () => import('./pages/news/news-details/news-details.module').then( m => m.NewsDetailsPageModule)
  },
  {
    title: 'PodcastDetailsPage',
    path: 'podcast/:slug',
    loadChildren: () => import('./pages/news/news-details/news-details.module').then( m => m.NewsDetailsPageModule)
  },
  {
    title: 'NewsDetailsPage',
    path: 'videos/:category/:slug',
    loadChildren: () => import('./pages/news/news-details/news-details.module').then( m => m.NewsDetailsPageModule)
  },
  {
    title: 'NewsDetailsPage',
    path: 'event/:slug',
    loadChildren: () => import('./pages/news/news-details/news-details.module').then( m => m.NewsDetailsPageModule)
  },
  {
    title: 'SettingsPage',
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    title: 'NewsBookmarksPage',
    path: 'bookmarks',
    loadChildren: () => import('./pages/news/news-bookmarks/news-bookmarks.module').then( m => m.NewsBookmarksPageModule)
  },
  {
    title: 'CoinsDetailsPage',
    path: 'kurs/:coinId',
    loadChildren: () => import('./pages/coin-details/coin-details.module').then( m => m.CoinDetailsPageModule)
  },
  {
    title: 'PortfolioDetailsPage',
    path: 'portfolio/:portfolioId/:coinId',
    loadChildren: () => import('./pages/coin-details/coin-details.module').then( m => m.CoinDetailsPageModule)
  },
  {
    title: 'CoinsPage',
    path: 'kurse',
    redirectTo: '/tabs/kurse'
  },
  {
    title: 'CoinsPage',
    path: 'coins',
    loadChildren: () => import('./pages/coin-tabs/coin-tabs.module').then( m => m.CoinTabsPageModule)
  },
  {
    title: 'PortfolioPage',
    path: 'coins/portfolio',
    loadChildren: () => import('./pages/coin-portfolio/coin-portfolio.module').then( m => m.CoinPortfolioPageModule)
  },
  {
    title: 'PortfolioAddTransactionPage',
    path: 'coins/portfolio/transaction/add/:portfolioId',
    loadChildren: () => import('./pages/coin-portfolio-transaction-add/coin-portfolio-transaction-add.module').then( m => m.CoinPortfolioTransactionAddPageModule)
  },
  {
    title: 'PortfolioEditTransactionPage',
    path: 'coins/portfolio/transaction/edit/:transactionId',
    loadChildren: () => import('./pages/coin-portfolio-transaction-add/coin-portfolio-transaction-add.module').then( m => m.CoinPortfolioTransactionAddPageModule)
  },
  {
    title: 'CoinTransactionsPage',
    path: 'kurs/:id/:portfolioId',
    loadChildren: () => import('./pages/coin-details/coin-details.module').then( m => m.CoinDetailsPageModule)
  },
  {
    title: 'CoinsSearchPage',
    path: 'coins/search',
    loadChildren: () => import('./pages/coin-search/coin-search.module').then( m => m.CoinSearchPageModule)
  },
  {
    title: 'PodcastChannelsPage',
    path: 'podcast/channels',
    loadChildren: () => import('./pages/podcast/podcast-channels/podcast-channels.module').then( m => m.PodcastChannelsPageModule)
  },
  {
    title: 'PodcastChannelsPage',
    path: 'podcast/list/:channelId',
    loadChildren: () => import('./pages/podcast/podcast-overview/podcast-overview.module').then( m => m.PodcastOverviewPageModule)
  },
  {
    title: 'PodcastTab',
    path: 'podcast',
    redirectTo: '/tabs/podcast'
  },
  {
    title: 'ErrorPage',
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    title: 'ProfilePage',
    path: 'profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    title: 'NewsFeedTabsPage',
    path: 'news-feed-tabs',
    loadChildren: () => import('./pages/news/news-feed-tabs/news-feed-tabs.module').then( m => m.NewsFeedTabsPageModule)
  },
  {
    title: 'SubscriptionPage',
    path: 'subscription',
    loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    title: 'OAuthCallbackPage',
    path: 'oauth/callback',
    loadChildren: () => import('./pages/oauth-callback/oauth-callback.module').then( m => m.OauthCallbackPageModule)
  },
  {
    title: 'PlusTab',
    path: 'plus-feed',
    loadChildren: () => import('./pages/news/plus-feed/plus-feed.module').then( m => m.PlusFeedPageModule)
  },
  {
    title: 'MorePage',
    path: 'more',
    loadChildren: () => import('./pages/more/more.module').then( m => m.MorePageModule)
  },
  {
    title: 'UserProfilePage',
    path: 'user/profile',
    loadChildren: () => import('./pages/user/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    title: 'OfferingPage',
    path: 'offering/:offeringId',
    loadChildren: () => import('./pages/offering/offering.module').then( m => m.OfferingPageModule)
  },
  {
    title: 'OnboardingPage',
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    title: 'ShortsTabsPage',
    path: 'shorts',
    loadChildren: () => import('./pages/video/video-library/video-library.module').then( m => m.VideoLibraryPageModule)
  },
  {
    title: 'ShortsVideoPage',
    path: 'shorts/:videoId',
    loadChildren: () => import('./pages/video/video-library-player/video-library-player.module').then( m => m.VideoLibraryPlayerPageModule)
  },
  {
    title: 'NotFoundRedirect',
    path: '**',
    redirectTo: 'error'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(Capacitor.isNativePlatform() ? routesMobile : routesDesktop,
      {
        preloadingStrategy: PreloadAllModules
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
