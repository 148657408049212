import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: string | number, locale?: string): string {
    if (value) {
      value = Number(value);
      if (Math.abs(value) >= Math.pow(10, 12)) {
        // trillion
        return this.decimalPipe.transform((value / Math.pow(10, 12)), '1.0-1', locale) + ' T';
        // value = (value / Math.pow(10, 9)).toFixed(1) + "B";
      } else if (Math.abs(value) >= Math.pow(10, 9)) {
        // billion
        return this.decimalPipe.transform((value / Math.pow(10, 9)), '1.0-1', locale) + ' B';
      } else if (Math.abs(value) >= Math.pow(10, 6)) {
        // million
        return this.decimalPipe.transform((value / Math.pow(10, 6)), '1.0-1', locale) + ' M';
      } else if (Math.abs(value) >= Math.pow(10, 3)) {
        // thousand
        // value = (value / Math.pow(10, 3)).toFixed(1) + 'K';
        return this.decimalPipe.transform(value, '1.0-0');
      } else if (Math.abs(value) >= Math.pow(10, 1)) {
        // hundred
        return this.decimalPipe.transform(value, '1.0-2', locale);
      } else if (Math.abs(value) >= 10) {
        // ten
        return this.decimalPipe.transform(value, '1.2-2', locale);
      } else if (Math.abs(value) >= 1) {
        // 1
        return this.decimalPipe.transform(value, '1.2-2', locale);
      } else if (Math.abs(value) >= 0.1) {
        return this.decimalPipe.transform(value, '1.2-2', locale);
      } else if (Math.abs(value) >= 0) {
        // zero
        return this.decimalPipe.transform(value, '1.4-6', locale);
      } else {
        return '-';
      }
    } else {
      return '0';
    }
  }

}
