<div class="circle-illustration">
  <img [src]=" 'assets/ui/circle-illustration-' + (isUserLoggedIn ? 'checkmark' : 'shop') + '.svg'" />
</div>
<div class="page-container">
  <!-- Content for not logged in users -->
  <div *ngIf="!isUserLoggedIn">
    <div style="position: relative;">
      <ion-card-title>
        Vielen Dank für deine Bestellung!
      </ion-card-title>
      <p class="text-block ion-text-center">
        Um dein App-Abo geräteübergreifend nutzen zu können, benötigst du einen BTC-ECHO Account. Deine Vorteile:
      </p>
      <ion-list class="advantages">
        <ion-item lines="none" *ngFor="let adventage of adventages">
          <ion-icon name="checkmark-circle" color="primary"></ion-icon>
          <ion-label [innerHTML]="adventage" class="ion-text-wrap"></ion-label>
        </ion-item>
      </ion-list>
    </div>
    <ion-footer>
      <ion-toolbar>
        <ion-button size="block" style="font-weight: 600;" (click)="openLogin()">
          Abo jetzt aktivieren
        </ion-button>
        <ion-button size="block" style="font-weight: 600;" color="light" (click)="closeModal()">
          Schließen
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </div>

  <!-- Content for logged in users -->
  <div *ngIf="isUserLoggedIn">
    <div style="position: relative;">
      <ion-card-title>
        Vielen Dank für deine Bestellung!
      </ion-card-title>
      <p class="ion-text-center">
        Du hast jetzt vollen Zugriff auf alle BTC-ECHO Plus+ Inhalte.
      </p>
    </div>
    <ion-footer>
      <ion-toolbar>
        <ion-button size="block" style="font-weight: 600;" (click)="closeModal()">
          Schließen
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </div>
</div>