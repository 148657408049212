import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, from, map, Observable, Subject } from 'rxjs';
import { FiatCurrency, CoingeckoService } from './providers/coingecko.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { WidgetsBridgePlugin } from 'capacitor-widgetsbridge-plugin';
import { CapacitorWidget } from '../package/widget';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public fiatCurrencyChanged$ = new Subject<FiatCurrency>(); // Event when fiat currency is changed
  public userIsBetaTester$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public forceShowOfferBar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private tickerSorting: string;

  constructor(
    private platform: Platform,
    private coingeckoService: CoingeckoService) { }

  async isFirstLaunch(): Promise<boolean> {
    const { value } = await Preferences.get({
      key: 'isFirstLaunch',
    });
    return !value;
  }

  async setFirstLaunch(): Promise<void> {
    return Preferences.set({
      key: 'isFirstLaunch',
      value: 'false'
    });
  }

  // ---------------------------------------------------
  // -------------------- BOOKMARKS --------------------
  // ---------------------------------------------------

  async addBookmark(postId: number) {
    try {
      const bookmarks = await this.getBookmarks();
      bookmarks.push(postId);
      return Preferences.set({
        key: 'bookmarks',
        value: JSON.stringify(bookmarks)
      });
    } catch (error) {
      console.error(error);
    } finally {
      console.log(`Added post ${postId} to bookmarks`);
    }
  }

  async removeBookmark(postId: number) {
    try {
      let bookmarks = await this.getBookmarks();
      bookmarks = bookmarks.filter(item => item !== postId);
      return Preferences.set({
        key: 'bookmarks',
        value: JSON.stringify(bookmarks)
      });
    } catch (error) {
      console.error(error);
    } finally {
      console.log(`Removes post ${postId} to bookmarks`);
    }
  }

  async isBookmarked(postId: number) {
    const bookmarks = await this.getBookmarks();
    return bookmarks.includes(postId);
  }

  async getBookmarks(): Promise<number[]> {
    const result = await Preferences.get({
      key: 'bookmarks'
    });
    return result.value ? JSON.parse(result.value) : [];
  }

  // ----------------------------------------------------
  // ------------------ COIN FAVORITES ------------------
  // ----------------------------------------------------

  /**
   * Add coin to favorites
   *
   * @param coinId e.g. "bitcoin"
   */
  async addCoinToFavorites(coinId: string) {
    try {
      const bookmarks = await this.getFavoriteCoins();
      bookmarks.push(coinId);
      return Preferences.set({
        key: 'bookmarkedCoins',
        value: JSON.stringify(bookmarks)
      });
    } catch (error) {
      console.error(error);
    } finally {
      console.log(`Added ${coinId} to favorites`);
    }
  }

  /**
   *
   * @param coinId e.g. "bitcoin"
   * @returns array of coin ids
   */
  async removeCoinFromFavorites(coinId: string) {
    try {
      let bookmarks = await this.getFavoriteCoins();
      bookmarks = bookmarks.filter(item => item !== coinId);
      return Preferences.set({
        key: 'bookmarkedCoins',
        value: JSON.stringify(bookmarks)
      });
    } catch (error) {
      console.error(error);
    } finally {
      console.log(`Remove ${coinId} to favorites`);
    }
  }

  /**
   * See if a coin is in favorites
   *
   * @param coinId e.g. "bitcoin"
   * @returns true / false
   */
  async coinIsInFavorites(coinId: string) {
    const bookmarks = await this.getFavoriteCoins();
    return bookmarks.includes(coinId);
  }

  /**
   * Get array of favorite coin ids
   *
   * @returns array of coin ids
   */
  async getFavoriteCoins(): Promise<string[]> {
    const result = await Preferences.get({
      key: 'bookmarkedCoins'
    });
    return result.value ? JSON.parse(result.value) : [];
  }

  // ---------------------------------------------------
  // ---------------- PODCAST BOOKMARKS ----------------
  // ---------------------------------------------------

  /**
   * Add a podcast episode to bookmarks
   */
  async addPodcastEpisodeToBookmarks(guid: string) {
    try {
      const guids = await this.getBookmarkedPodcastEpisodes();
      guids.push(guid);
      await Preferences.set({
        key: 'podcast_bookmarks',
        value: JSON.stringify(guids)
      });
    } catch (error) {
      console.error(error);
    } finally {
      console.log(`Added Episode "${guid}" to Bookmarks`);
    }
  }

  /**
   * Remove a podcast from bookmarks
   */
  async removePodcastEpisodeFromBookmarks(guid: string) {
    try {
      let guids = await this.getBookmarkedPodcastEpisodes();
      guids = guids.filter(bookmark => bookmark !== guid);
      await Preferences.set({
        key: 'podcast_bookmarks',
        value: JSON.stringify(guids)
      });
    } catch (error) {
      console.error(error);
    } finally {
      console.log(`Removed Guid "${guid}" from Bookmarks`);
    }
  }

  /**
   * Check if a podcast episode is bookmarked
   */
  async podcastEpisodeIsBookmarked(guid: string): Promise<boolean> {
    const bookmarks = await this.getBookmarkedPodcastEpisodes();
    return bookmarks.includes(guid);
  }

  /**
   * Returns a list bookmarked podcast GUIDs
   */
  async getBookmarkedPodcastEpisodes(): Promise<string[]> {
    const result = await Preferences.get({
      key: 'podcast_bookmarks'
    });
    return result.value ? JSON.parse(result.value) : [];
  }

  // --------------------------------------------------
  // ---------------------- FIAT ----------------------
  // --------------------------------------------------

  async setFiatCurrency(fiat: FiatCurrency) {
    await Preferences.set({
      key: 'fiatCurrency',
      value: JSON.stringify(fiat)
    });

    console.log('Fiat currency changed to', fiat);

    // emit event informing about the change
    this.fiatCurrencyChanged$.next(fiat);
  }

  async getFiatCurrency(): Promise<FiatCurrency> {
    const result = await Preferences.get({
      key: 'fiatCurrency'
    });
    return result.value
      ? JSON.parse(result.value)
      : this.coingeckoService.getFiatCurrency('eur');
  }

  getFiatCurrencyObs(): Observable<FiatCurrency> {
    return from(Preferences.get({
      key: 'fiatCurrency'
    })).pipe(map(res => res.value
      ? JSON.parse(res.value)
      : this.coingeckoService.getFiatCurrency('eur')));
  }

  // ---------------------------------------------------
  // ------------------ TICKER SORTING -----------------
  // ---------------------------------------------------

  /**
   * Stores the ticker sorting for the current session
   *
   * @param sorting TickerSorting
   */
  setTickerSorting(sorting: string) {
    this.tickerSorting = sorting;
  }

  /**
   * Get the ticker sorting for the current session
   */
  getTickerSorting(): string {
    return this.tickerSorting || 'market_cap_desc';
  }

  // ---------------------------------------------------
  // ------------------- APP REVIEW --------------------
  // ---------------------------------------------------
  async setAppReviewAnswered() {
    return Preferences.set({
      key: 'appReviewAnswered',
      value: JSON.stringify(true)
    });
  }

  async getAppReviewAnswered(): Promise<boolean> {
    const result = await Preferences.get({
      key: 'appReviewAnswered'
    });
    return result.value ? JSON.parse(result.value) : false;
  }

  // ---------------------------------------------------
  // -------------------- DARK MODE --------------------
  // ---------------------------------------------------

  async setTheme(theme: Theme) {
    switch (theme) {
      case 'auto':
        document.body.classList.remove('dark');
        document.body.classList.remove('light');
        if (this.platform.is('capacitor') && this.platform.is('ios')) {
          await StatusBar.setStyle({ style: Style.Default });
        }
        break;
      case 'light':
        document.body.classList.remove('dark');
        document.body.classList.add('light');
        if (this.platform.is('capacitor') && this.platform.is('ios')) {
          await StatusBar.setStyle({ style: Style.Light });
        }
        break;
      case 'dark':
        document.body.classList.remove('light');
        document.body.classList.add('dark');
        if (this.platform.is('capacitor') && this.platform.is('ios')) {
          await StatusBar.setStyle({ style: Style.Dark });
        }
        break;
      default:
        return;
    }

    console.log('Set theme to', theme);

    return Preferences.set({
      key: 'theme',
      value: theme
    });
  }

  async getTheme() {
    const theme = await Preferences.get({
      key: 'theme'
    });
    return (theme.value || 'auto') as Theme;
  }

  async initTheme() {
    const theme = await this.getTheme();
    await this.setTheme(theme);
  }

  async checkBetaTesterState(): Promise<boolean> {
    const {value} = await Preferences.get({
      key: 'isBetaTester'
    });

    const isBetaTester = JSON.parse(value);
    this.userIsBetaTester$.next(isBetaTester);

    this.setUserAsBetaTester(isBetaTester);
    console.log('checkBetaTesterState', isBetaTester);

    return isBetaTester;
  }//

  async setUserAsBetaTester(value: boolean): Promise<void> {
    console.log('setUserAsBetaTester', value);
    await Preferences.set({
      key: 'isBetaTester',
      value: String(value)
    });
    this.userIsBetaTester$.next(value);
  }

  /**
   * This method should be called on app start to make sure that the shared preferences have values set.
   */
  async initWidgets() {
    const coinsMap = {
      'coin-0': 'bitcoin',
      'coin-1': 'ethereum',
      'coin-2': 'tether',
      vs_currency: 'eur'
    };
    if(this.platform.is('capacitor')) {
      console.log('initCoins');
      // for loop with three iterations
      for (const [key, value] of Object.entries(coinsMap)) {
        try {
          let coinId = null;
          const getOptions = {
            key,
            group: this.platform.is('ios') ? 'group.de.btcecho.app.widgets' : 'de.btcecho.app.widgets'
          };
          const setOptions = {
            ...getOptions,
            value
          };
          if (this.platform.is('ios')) {
            const { results } = await WidgetsBridgePlugin.getItem(getOptions);
            coinId = results;
            if (!coinId) {
              await WidgetsBridgePlugin.setItem(setOptions);
            }
          } else {
            const { results } = await CapacitorWidget.getItem(getOptions);
            coinId = results;
            if (!coinId) {
              await CapacitorWidget.setItem(setOptions);
            }
          }
        } catch(e) {
          // could not load coin from user defaults
          console.log(e);
        }
      }
    }
  }

}

export type Theme = 'dark' | 'light' | 'auto';

interface PodcastBookmark {
  episodeId: number;
  channelId: string;
}
