import { Pipe, PipeTransform } from '@angular/core';
import { toZonedTime } from 'date-fns-tz';
import { formatDistance  } from 'date-fns';
import { de } from 'date-fns/locale';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: number | string | Date): string {

    const timezone = 'Europe/Berlin';

    return formatDistance(
      toZonedTime(new Date(value), timezone),
      toZonedTime(new Date(), timezone),
      {
        addSuffix: true,
        locale: de
      }
    );
  }

}
