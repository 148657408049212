import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AnimationController, IonModal, ModalController, GestureController, Gesture } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { HTMLSwiperElement } from 'src/app/types/swiper';
import Swiper from 'swiper';
import { Zoom } from 'swiper/modules';

// Init Swiper Plugins
Swiper.use([Zoom]);

@Component({
  selector: 'app-zoomable-image',
  templateUrl: './zoomable-image.component.html',
  styleUrls: ['./zoomable-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomableImageComponent implements OnInit, AfterViewInit {
  @Input() imageUrl: string;
  @ViewChild('swiperRef', { static: true }) swiperRef: ElementRef<HTMLSwiperElement>;
  @ViewChild('modal', { static: true }) modal: IonModal;

  swiperConfig: any = {
    zoom: {
      maxRatio: 2,
    },
    cssMode: true
  };

  rotationDegrees = 0;
  rotatedBase64$ = new BehaviorSubject<string>(null);

  constructor(private modalController: ModalController,
              private animationCtrl: AnimationController
  ) { }

  ngOnInit() {
    console.log('ngOnInit');
    const enterAnimation = (baseEl: HTMLElement) => {
      const root = baseEl.shadowRoot;

      const backdropAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' },
        ]);

      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing('ease-in-out')
        .duration(250)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };

    const leaveAnimation = (baseEl: HTMLElement) => enterAnimation(baseEl).direction('reverse');

    this.modal.enterAnimation = enterAnimation;
    this.modal.leaveAnimation = leaveAnimation;
  }

  ngAfterViewInit() {
    // Prepare rotated image
    this.rotateImageById('zoomableImage').then(rotatedBase64 => {
      this.rotatedBase64$.next(rotatedBase64 as string);
    }).catch(error => {
      console.error('Error rotating image:', error);
    });
  }

  /**
   * Closes the modal component by dismissing it using the modal controller.
   */
  close() {
    this.modalController.dismiss();
  }

  /**
   * Toggles the rotation of the image between 0 and 90 degrees.
   */
  toggleRotation() {
    this.rotationDegrees = this.rotationDegrees === 0 ? 90 : 0;
  }

  /**
   * Rotates the image by a given ID by 90 degrees and returns the base64 string of the rotated image.
   * @param imageId The ID of the image to be rotated.
   * @returns A promise that resolves with the base64 string of the rotated image.
   */
  rotateImageById(imageId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const imageElement = document.getElementById(imageId);
      if (!imageElement) {
        reject(new Error('Image element not found'));
        return;
      }

      // Ensure the element is an HTMLImageElement
      if (!(imageElement instanceof HTMLImageElement)) {
        reject(new Error('Element is not an HTMLImageElement'));
        return;
      }

      // Set crossOrigin attribute to allow CORS
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = imageElement.src;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set canvas dimensions to rotated dimensions
        canvas.width = image.naturalHeight;
        canvas.height = image.naturalWidth;

        // Translate and rotate canvas
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(270 * Math.PI / 180);
        ctx.drawImage(image, -image.naturalWidth / 2, -image.naturalHeight / 2);

        // Convert canvas to base64
        const rotatedImageBase64 = canvas.toDataURL('image/png');
        resolve(rotatedImageBase64);
      };

      image.onerror = (error) => {
        reject(new Error('Failed to load image'));
      };
    });
  }

}
