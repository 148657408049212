import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Preferences } from '@capacitor/preferences';
import { ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBoxComponent implements OnInit {

  showInfoBox = new BehaviorSubject(false);
  preferencesKey = 'infobox_widgets'; // Also change in settings-toggle.component.ts

  private navController = inject(NavController);
  private modalController = inject(ModalController);

  constructor() { }

  async ngOnInit() {
    const { value } = await Preferences.get({ key: this.preferencesKey });
    this.showInfoBox.next(value !== 'hide');
  }

  async openBrowser(url: string) {
    await Browser.open({
      url
    });
  }

  /**
   * Has own function because it's used in multiple places
   */
  openHelpCenter() {
    this.openBrowser('https://139514281.hs-sites-eu1.com/hilfe-center');
  }

  async navigateTo(route: string) {
    if (!route) {
      return;
    }
    await this.closeSettingsModal();
    await this.navController.navigateForward(route);
  }

  async close() {
    this.showInfoBox.next(false);
    await Preferences.set({ key: this.preferencesKey, value: 'hide' });
  }

  async closeSettingsModal() {
    await this.modalController.dismiss();
  }

}
