<ion-card *ngIf="showInfoBox | push">
  <ion-item lines="full">
    <ion-label>
      <strong>Coin Widgets</strong>
    </ion-label>
    <ion-button color="dark" size="small" fill="clear" slot="end" (click)="close()">
      <ion-icon name="close" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-card-content>
    <p>Du kannst nun unter dem Punkt „Widgets“ auswählen, welche Coins und welche Fiat-Währung in den Widgets gezeigt werden sollen.</p>
  </ion-card-content>
  <ion-item lines="none">
    <ion-label color="primary" (click)="navigateTo('/settings/widgets')">
      Zu den Einstellungen
    </ion-label>
  </ion-item>
  <!-- <ion-item lines="none">
    <ion-label color="primary" (click)="openHelpCenter()">
      Support kontaktieren
    </ion-label>
  </ion-item> -->
</ion-card>