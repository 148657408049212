import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chatbase',
  templateUrl: './chatbase.component.html',
  styleUrls: ['./chatbase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatbaseComponent implements OnInit {

  url = inject(DomSanitizer).bypassSecurityTrustResourceUrl(environment.chatBaseUrl);
  modalController = inject(ModalController);

  ngOnInit() {
    console.log('ChatbaseComponent');
  }

  async close() {
    await this.modalController.dismiss();
  }

}
