/* eslint-disable max-len */
export const environment = {
  production: true,
  staging: false,
  development: false,
  name: 'prod',
  appfigures: {
    clientKey: 'e8c75078dbe644928c25b6aaeed8e9f5',
    username: 'btc-echo.appfigures@eyloo.com',
    password: '3NVG#dz&!AZE2JrP&q'
  },
  cleverpush: {
    channelId: 'cmE58HSwwwpk6SciQ'
  },
  btcecho: {
    apiUrl: 'https://www.btc-echo.de/btcegql',
    apiUrlShop: 'https://www.btc-echo.de/shop/btcsgql',
    apiAuth: 'YnRjZWNob25ld3NhcHA6UFpZaSAwcXVzIG9Yd0UgVmRjSiB6V3BsIDdhQ1Y=',
    websiteUrl: 'https://www.btc-echo.de',
    shopUrl: 'https://www.btc-echo.de/shop',
    shopCheckoutSlug: 'checkout',
    privacyPolicyUrl: 'https://info.btc-echo.de/datenschutzerklaerung_app',
  },
  stripe: {
    publishableKey: 'pk_live_6yjB6PeISEhAFTzw5Ytv4xC4',
    api: 'https://btc-echo-app-api.vercel.app'
  },
  sanity: {
    projectId: 'ed60z6z7',
    dataset: 'production',
    token: 'skMJbIrRKDgAa9qfmvbYPRssepU6cPFNeUSKKz7kYiO7Lg9xSJRlcLtrZcrjxGsOOA5VEJOAb3PN6QE8BuVK2HpMwjySE604koXvfZOOcWGBEIpmgEQtQED0onOqBxfDfWl81ZoOwC51eV3Z3xRegxuCI0hiS8NKQ5Gj4ImYERXKwsztUf81'
  },
  unidy: {
    clientId: 'nKVKoLnguwMhWdy0qxV4HJst9NvP9Az2GdrpsJ4MwWM',
    subscriptionIdMonthly: '98127328-889a-457a-8ac4-efcaf57e3008',
    subscriptionIdYearly: '1773785a-b92a-43ec-9acc-20aed412442a',
    issuer: 'https://account.btc-echo.de',
    callbackUrlApp: 'btcecho://oauth/callback',
    callbackUrlWeb: 'https://btc-echo-user-area.pages.dev/oauth/callback'
  },
  paypal: {
    api: 'https://api-m.paypal.com'
  },
  apple: {
    merchantId: 'merchant.de.btcecho.applepay'
  },
  revenueCat: {
    apiKeyIos: 'appl_oNHLcfduDdtpFEBtUpRIylePEYe',
    apiKeyAndroid: 'goog_RBEzIEsfJWnBDIyZyoBthdpScFt'
  },
  oneSignal: {
    appId: '2c0d9849-7cd6-4409-8b94-a2c131df1c5e'
  },
  supabase: {
    api: 'https://btc-echo-app-api.vercel.app/api'
  },
  hubspot: {
    api: 'https://btc-echo-app-api.vercel.app/api',
  },
  vercel: {
    api: 'https://btc-echo-app-api.vercel.app/api',
  },
  chatBaseUrl: 'https://www.chatbase.co/chatbot-iframe/zD_laP3FiJ85SZ2aNXDIG',
  beyondWords: {
    apiUrl: 'https://api.beyondwords.io/v1',
    apiKey: 'write_7314392b54a1e2534396137849028a07_459dd48863a835b41b4ce62009b886a5',
    projectId: '45256',
  }
};
