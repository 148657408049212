<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="toggleRotation()">
        <ion-icon name="refresh-outline" [class]="'rotate-' + rotationDegrees"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="close()">Schließen</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content [scrollY]="false">
    <swiper-container class="mySwiper" zoom="true" #swiperRef>
      <swiper-slide>
        <div [class]="['swiper-zoom-container', 'swiper-slide-transform']">
          <img [src]="rotationDegrees === 0 ? imageUrl : (rotatedBase64$ | push)" alt="Image" class="swiper-zoom-image" id="zoomableImage"/>
        </div>
      </swiper-slide>
    </swiper-container>
</ion-content>
